import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";

export default () => ({
  init() {
    const lightbox = new PhotoSwipeLightbox({
      // may select multiple "galleries"
      // gallery: `#${this.$root.id}`,
      gallery: `#${this.$root.id}`,

      // Elements within gallery (slides)
      children: "a",

      // setup PhotoSwipe Core dynamic import
      pswpModule: PhotoSwipe,
    });

    lightbox.init();
  },
});
